import React from 'react';

export const Home = () => (
  <div>
    <h1>ashik meerankutty</h1>
    <div>
      <ul>
        <li>
          <a href="https://github.com/ashikmeerankutty">github</a>
        </li>
        <li>
          <a href="https://www.linkedin.com/in/ashikmeerankutty/">linkedin</a>
        </li>
        <li>
          <a href="https://x.com/mr_devops_">x.com</a>
        </li>
        <li>
          <a href="https://blog.driftbyte.xyz/">blog</a>
        </li>
        <li>
          <a href="mailto:ping@driftbyte.xyz">contact (ping@driftbyte.xyz)</a>
        </li>
      </ul>
    </div>
  </div>
);

export default Home;
